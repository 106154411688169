@import '../styles.scss';

div.container-bg-color {  
  background-image: url("../assets/img/bakgrunn_loggin.png");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover; /* Resize the background image to cover the entire container */
  //min-height: 84.5%;
  height: 100%;
}

.container-custom-icon-background {
  display: inline-block;
  width: 100%;
  text-align: center;

  .custom-icon {
    display: inline-block;
    margin: 20px;
    cursor: pointer;
    font-weight: 500;
  }

  .custom-icon-background {
    // background: #f2f9fd; //#d4b9d7-red
    border-radius: 300px;
    height: 60px;
    width: 60px;
    line-height: 53px;
    text-align: center;
    margin: 0px auto;

    &.selected {
      // background: #d4b9d7;
      background-color: #C9E9FF;
    }

    img {
      width: 45px;
    }
  }
}

@media (max-width: 768px) {
  .container-main {
    div.row {
      margin-left: 3px;
      margin-right: 3px;
    }
  }

  div.container-bg-color {
    min-height: 84,4%;
  }

  .container-fluid.container-bg-color,
  .container-bg-color>.container-fluid.container-bg-color,
  .container-bg-color>.container.container-main,
  .container-bg-color .form-container {
    padding: 0px;
  }

  .container-custom-icon-background {
    .custom-icon {
      width: 23%;
      margin: 5%;
      vertical-align: top;
    }
  }



}



.fill { 
  min-height: 84.4%;
  //min-height: 85vh;
}

@media (max-width: 768px) {
  .fill {
    min-height: 84.4%;
      // width: 3rem;
  }
 
}

.text-primary{
  color:  $primary;
 }

 .bg-primary-header{
  background-color: #28A1CC;
  border-color: #28A1CC;
  color: #fff;
  border-radius: 5px;
}


.icon-flex, .icon-wrapper {
  min-width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-wrapper {
  border-radius: 50%;
  overflow: hidden;
  border: 4px solid #C6C6C6;
  font-size: 1em;
  width: 2rem;
  height: 2rem;
  background-color: #C6C6C6;
}
.icon-wrapper .bi {  
  color: #fff;
}
.icon-wrapper-add{
  font-size: 2em;
}
.btn-wrapper-delete{
  font-size: 1em;
  background-color: #FE6464;
  border: 2px solid #FE6464;
  color: #fff !important;
  cursor: pointer;
}
.btn-wrapper-delete:hover {
  background-color: #e45a5a;
}
.icon-wrapper.btn-wrapper-tranparent > i {
  color: #000 !important;
}
.btn-wrapper-tranparent{
  background-color: transparent;
  border: 2px solid #C6C6C6;
  color: #000 !important;
  cursor: pointer;
}
.btn-wrapper-tranparent:hover {
  font-weight: bold;
}
.btn-wrapper-tranparent:disabled {
  background-color: #C6C6C6;
  border-color: #C6C6C6;
  color: white;
}

.btn-wrapper-info{
  background-color: #318ce7;
  border: 2px solid #318ce7;
  color: #fff !important;
  cursor: pointer;
}
.btn-wrapper-info:hover {
  background-color: #3d85c6;
}
.btn-wrapper-info:disabled {
  background-color: #C6C6C6;
  border-color: #C6C6C6;
  color: white;
}

.row-warning {
  background-color: #FDF6D7;
}

.chevron{

  &::before{
    margin-top: 5px;
    border-style: solid;
    border-width: 0.15em 0.15em 0 0;
    content: '';
    display: inline-block;
    height: 0.45em;
    left: 0.15em;
    position: relative;
    top: 0.15em;
    transform: rotate(-45deg);
    vertical-align: top;
    width: 0.45em;
    top: 0;
    transform: rotate(135deg);
  }
  &.active{
    &::before{
      transform: rotate(-45deg);
      right: -2px;

    }
    &::after{
      width: 15px;
      transform: rotate(45deg);
      left: -2px;

    }
  }
}

.notecard {
  //--note-background: var(--background-information);
  //--note-theme: #0085f2;
  background-color: #E5F3FE;
  border-left: 2px solid #0085f2;
  border-radius: var(--elem-radius);
  box-shadow: var(--shadow-01);
  margin: 1rem 0;
  padding: 1rem 1rem 1rem 3rem;
  position: relative;
}

.title-sub-menu {
  padding-bottom: 15px;
  background-color: #E5F3FE;
}

.title-container {
  p {
      display: inline-block;
  }
}

.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.content-container {
  display: block;
}
.warning-box {
  display: block;
  padding: 8px 8px;
  background-color: #FDF6D7;
  margin: 0 0 8px 0;
  border: solid 1px #ffc4c4;
}