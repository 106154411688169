/* You can add global styles to this file, and also import other style files */
// @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,900;0,500;1,500&display=swap');


:root {
  $primary-rgb: rgb(28, 67, 150);
  $primary: #1c4396;
  $secondary:#112051;
  html,body {
    height: 100%;
    min-height: 100vh;
  }
}
html,body {
  height: 100%;
  min-height: 100vh;
}
$primary-rgb: rgb(28, 67, 150);
$secondary:#112051;

$primary: #28A1CC;
$blue: #163b74 !default;
$ctgBase: #0846A5 !default;
//$primary:       $ctgBase !default;
$body-bg: #fff;
$bg-grey: #EBEBEB;
$bg-grey-second: #F5F5F5;

* {
  // override bootstrap colors
  //--bs-primary: #fff;
  //--bs-primary-rgb: 255, 255, 255;


}



.btn-primary:not(:disabled) {
  color: #fff;
}

//--bs-card-bg:  rgba(#1c4294, .5);

//darken( #F6F5F6, 2% );
// lighten( #4A224D, 50% );

$btn-border-radius: 0.4rem;

$navbar-padding-y: 2rem;
$navbar-dark-color:  $secondary;
$navbar-nav-link-padding-x: 2rem;
$navbar-dark-color: White;
// $navbar-dark-hover-color: lighten(#4A224D, 50%);
$navbar-dark-active-color: rgba(#4A224D, .9);
$navbar-dark-disabled-color: rgba(#4A224D, .3);
$navbar-dark-toggler-border-color: rgba(#4A224D, .1);

$font-family-sans-serif: 'Helvetica Neue', sans-serif,  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family: 'Helvetica Neue', sans-serif;
$form-check-input-checked-color: #007CAE;
$form-check-input-checked-bg-color: white;
$form-check-input-border-radius: .101em;


.nav-link {

  color: #fff;
  font-family: $font-family !important;
  font-size: 16px;
  font-weight: 600 !important;
  //line-height: 35px; 
  &:hover,
  &:focus {
    color: darken($ctgBase, 50%);
  }

  // Disabled state lightens text
  &.active {
    text-decoration: underline;
    color: darken($ctgBase, 50%);
  }
}





.container-small {
  background-color: #fff;

  .title {
    font-size: 28px;
    font-weight: 500;
  }
}

/* Importing Bootstrap SCSS file. */
@import "../node_modules/bootstrap/scss/bootstrap";

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.2/font/bootstrap-icons.min.css");